import {
	getIonValues,
	runMingo,
	unFlatten,
} from '@buddy-technology/ion-helpers';
import hasCalculate from './hasCalculate';

/**
 * Computes initial ION values by running calculations on the provided flat application data and setting defaults for missing data.
 *
 * @param {Object} params - The parameters object.
 * @param {Object} params.flatApplication - The flat application data.
 * @param {Object} [params.incomingData={}] - The incoming data to merge with the flat application data.
 * @param {Object} [params.context={}] - Additional context to use in calculations (variables, offeringOptions, etc.).
 * @returns {Object} - An object containing the calculated ION values to initialize our state with.
 */
const getInitialIonValues = ({ flatApplication, incomingData = {}, context = {} }) => {
	const ionValues = getIonValues(flatApplication, incomingData);
	const calculatedValues = Object.entries(ionValues).reduce((acc, [key, value]) => {
		if (hasCalculate(value)) {
			const { value: result } = runMingo(value._calculate, {
				...unFlatten(ionValues),
				...context,
			});
			acc[key] = result;
		} else {
			acc[key] = value;
		}
		return acc;
	}, {});
	return calculatedValues;
};

export default getInitialIonValues;
