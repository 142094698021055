import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import ViewContent from './ViewContent';
import StepThroughNav from './StepThroughNav';
import useMultipleActions from '../../hooks/useMultipleActions';
import { useEventContext } from '../../context/EventContext';
import { Markdown } from '../UI';
import { getIframeUrl } from '../../utils';
import {
	ACTION_SUB_TYPES,
	ACTION_TYPES,
	BASE_VIEW_STATUSES,
	CONTENT_POSITION_TYPES,
	NAV_ACTIONS,
	RENDERED_VIEW_TYPES,
} from '../../models/dictionary';
import IF from './IF';

function RenderedView({
	action,
	backButtonLabel,
	clearErrors,
	content = [],
	control,
	errors,
	extraContent,
	getValues,
	onCheckout = () => {},
	hideBack,
	hideNext,
	id: viewId,
	initialView = '',
	ionId,
	iframe = {},
	lastNavAction,
	navigate,
	nextButtonLabel,
	placesApiKey,
	setError,
	setValue,
	showNav,
	stage,
	status = '',
	subTitle,
	title,
	trigger,
	type,
	updateCurrentView,
	updateAppState,
	variables,
	watch,
	theme,
	...viewProps
}) {
	const contentBelowNav = content.filter(({ position }) => position === 'BELOW_NAV');
	const filteredContent = content.filter(({ position }) => position !== 'BELOW_NAV');

	const isCheckout = type === RENDERED_VIEW_TYPES.CHECKOUT;

	const extractPanels = (array) => array.reduce((acc, item) => {
		if ('panels' in item) {
			return acc.concat(item.panels);
		}
		return acc;
	}, []);

	const tabPanels = extractPanels(content);

	const containerRef = useRef();
	const eventContext = useEventContext();

	const {
		eventCallback,
		viewType,
		partnerId,
	} = eventContext;

	const {
		allowPaymentRequest,
		height = '500px',
		src,
		title: iframeTitle = 'Embedded Document',
		width = '100%',
	} = iframe;

	const iframeSrc = src ? getIframeUrl(iframe.src, getValues) : null;

	const validateFields = (actionFields) => {
		const currentFieldIds = content.reduce((running, current) => {
			if (current.fields) {
				const fieldIds = current.fields.map(({ id }) => id);
				return [...running, ...fieldIds];
			}
			return running;
		}, []);
		const fieldsToTrigger = [...currentFieldIds, ...actionFields];
		return trigger(fieldsToTrigger);
	};

	const {
		isLoading, onNext, disableBack, shouldSkip,
	} = useMultipleActions(action, {
		control,
		getValues,
		iframeSrc,
		ionId,
		lastNavAction,
		onCheckout,
		navigate,
		partnerId,
		setValue,
		stage,
		trigger,
		validateFields,
		viewType,
	});

	// const callErrors = Object.values(errors).map(({ message }) => <p key={message.replace(/\s/g, '')}>{message}</p>);

	useEffect(() => {
		if (viewType === 'paginated') {
			document.title = `${partnerId} | ${viewId}`;
			eventCallback('onViewChange', { timestamp: Date.now(), viewId: viewId || title });
		}
		if (viewType === 'single-form') {
			const observer = new IntersectionObserver((entry) => {
				if (entry[0].isIntersecting) {
					document.title = `${partnerId} | ${viewId}`;
					eventCallback('onScrollToView', { timestamp: Date.now(), viewId: viewId || title });
					updateCurrentView(viewId);
				}
			});
			observer.observe(containerRef.current);
		}
	}, []);

	useEffect(() => {
		/*
			TODO: This does flash a different view unfortunately.
			Fixing that will involve a big rewrite of the navigation and dynamic view system. A separate issue will be created for that, but don't want to impact allstate.
		*/
		if (shouldSkip && [NAV_ACTIONS.NEXT, NAV_ACTIONS.BACK].includes(lastNavAction)) {
			navigate(lastNavAction);
		}
	}, [shouldSkip]);

	if (shouldSkip) {
		return null;
	}

	const getNextLabel = (id) => {
		if (initialView === id) {
			return 'Continue';
		}
		if (id === BASE_VIEW_STATUSES.CHECKOUT) {
			return 'Checkout';
		}
		return 'Next';
	};

	return (
		<div className="super-view-container" id={`${viewId}-view-container`} ref={containerRef}>
			<div className="view-container" id={`${viewId}-view-container`}>
				<IF condition={title}>
					<h2 className="view-title" id={`${viewId}-view-title`}>{title}</h2>
				</IF>
				<IF condition={subTitle}>
					<h3 className="view-subtitle" id={`${viewId}-view-subtitle`}>{subTitle}</h3>
				</IF>
				<IF condition={iframeSrc}>
					<div id={`${viewId}-iframe-container`} className="iframe-container">
						<iframe
							// eslint-disable-next-line react/no-unknown-property
							allowpaymentrequest={allowPaymentRequest}
							src={iframeSrc}
							title={iframeTitle}
							width={width}
							height={height}
						/>
					</div>
				</IF>
				<IF condition={extraContent}>
					{extraContent}
				</IF>
				{filteredContent.map((contentObj, i) => (
					<ViewContent
						// index in key is ok here because the content array won't change
						// eslint-disable-next-line react/no-array-index-key
						key={`${viewId}-content-${i}`}
						index={i}
						content={contentObj}
						errors={errors}
						setValue={setValue}
						getValues={getValues}
						updateAppState={updateAppState}
						variables={variables}
						viewId={viewId}
						trigger={trigger}
						setError={setError}
						clearErrors={clearErrors}
						control={control}
						placesApiKey={placesApiKey}
						tabPanels={tabPanels}
						{...viewProps}
					/>
				))}
			</div>
			<IF condition={showNav}>
				<StepThroughNav
					nextButtonLabel={nextButtonLabel || getNextLabel(viewId)}
					backButtonLabel={backButtonLabel}
					onClickNext={onNext}
					isLoading={isLoading}
					disableBack={disableBack}
					navigate={navigate}
					hideBack={hideBack || status === initialView || status === BASE_VIEW_STATUSES.CHECKOUT}
					// if initial view, hide back button
					hideNext={hideNext || status === BASE_VIEW_STATUSES.CHECKOUT}
					status={status}
				/>
			</IF>
			{/* Come back to this, Cezar!  */}
			{/* <IF condition={Object.keys(errors).length}>
				<div id={`${viewId}-call-errors`} className="input-error">
					Call failed due to the following errors:
					{callErrors}
				</div>
			</IF> */}

			{/* Checkout views render their below-nav content outside of the RenderedView component */}
			<IF condition={contentBelowNav?.length && !isCheckout}>
				{contentBelowNav?.map((md, index) => (
					<Markdown
						getValues={getValues}
						variables={variables}
						// index is guaranteed to be stable here since it comes from static ion.
						// some IONs are using the same markdown in multiple places on the same view, so we need this to identify.
						// eslint-disable-next-line react/no-array-index-key
						key={`${md?.text}-${index}`}
						{...md}
						index={index}
						viewId={viewId}
						control={control}
						theme={theme}
					/>
				))}
			</IF>
		</div>
	);
}

const actionShape = PropTypes.shape({
	actionType: PropTypes.oneOf(Object.values(ACTION_TYPES)),
	type: PropTypes.oneOf(Object.values(ACTION_SUB_TYPES)),
});

// TODO: come back and stub the array and object types below.
RenderedView.propTypes = {
	action: PropTypes.oneOfType([
		actionShape,
		PropTypes.arrayOf(actionShape),
	]),
	content: PropTypes.arrayOf(PropTypes.shape({
		text: PropTypes.string,
		position: PropTypes.oneOf(Object.values(CONTENT_POSITION_TYPES)),
		isHidden: PropTypes.oneOfType([
			PropTypes.bool,
			PropTypes.shape({
				// can't describe mingo array shapes.
				// eslint-disable-next-line react/forbid-prop-types
				_calculate: PropTypes.array,
			}),
		]),
	})),
	// eslint-disable-next-line react/forbid-prop-types
	errors: PropTypes.object,
	extraContent: PropTypes.element,
	// eslint-disable-next-line react/forbid-prop-types
	fields: PropTypes.array,
	id: PropTypes.string.isRequired,
	iframe: PropTypes.shape({
		src: PropTypes.string,
	}),
	getValues: PropTypes.func.isRequired,
	hideBack: PropTypes.bool,
	hideNext: PropTypes.bool,
	initialView: PropTypes.string,
	status: PropTypes.string,
	showNav: PropTypes.bool.isRequired,
	navigate: PropTypes.func.isRequired,
	setValue: PropTypes.func.isRequired,
	subTitle: PropTypes.string,
	title: PropTypes.string,
	onCheckout: PropTypes.func,
	updateCurrentView: PropTypes.func.isRequired,
	updateAppState: PropTypes.func.isRequired,
	// eslint-disable-next-line react/forbid-prop-types
	variables: PropTypes.object.isRequired,
};

export default RenderedView;
